import React, { useState } from 'react';
import { Dropdown, Menu, Skeleton, message } from 'antd';
import { Link } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { colors, fonts, media } from '@app/themes';
import styled from 'styled-components';
import DeleteDialogue from '../DeleteDialogue';
import { translate } from '../IntlGlobalProvider';
import ArrowImg from '@images/open.svg';
import Copyscript from 'images/copyscript.svg';
import { T } from '../T';
import ToastMessage from '../ToastMessage';

interface ISiteCardProps {
  name: string;
  websiteUrl: string | null;
  projectId: string;
  handleDelete: (projectId: string) => void;
  previewUrl: string | null;
  scriptCode: string | null;
}

interface StyledCoverImageProps {
  imgUrl: string | null;
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(17, 17, 17, 0.9) 0%, rgba(17, 17, 17, 0.9) 100%);
  opacity: 0; /* Initially not visible */
  transition: opacity 0.3s ease-in-out;
`;

const StyledCard = styled.div`
  position: relative;
  box-sizing: border-box;
  background-color: #52555c !important;
  width: 22rem;
  height: 16.25rem;
  border: 1px solid ${colors.steelGray};
  border-radius: 0.5rem;
  overflow: hidden;

  &:hover ${Overlay} {
    opacity: 1; /* Show the overlay on hover */
  }
  ${media.lessThan('mobile')`
  width:100%;
  `}
`;

const CoverImage = styled.div<StyledCoverImageProps>`
  background-image: ${(props) => `url(${props.imgUrl})`};
  height: 12.313rem;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
`;

const CardFooter = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colors.steelGray};
`;

const StyledLink = styled.a`
  text-decoration: none;
  font-weight: 400;
  ${fonts.size.regular}
  margin-top: 0.5rem;
  color: ${colors.neutral30};

  ${media.lessThan('mobile')`
    font-size:0.8rem; 
  `}
`;

const ProjectName = styled.h5`
  margin: 0;
  color: ${colors.neutral00};
  ${fonts.size.regular}
  font-weight: 400;
  line-height: normal;
`;

const OpenDesignButton = styled.button`
  height: 2.625rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  color: ${colors.neutral00};
  ${fonts.size.regular};
  ${fonts.family.DMSans};
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primary100};
  border: none;
  cursor: pointer;

  &:hover {
    color: ${colors.neutral00};
  }
`;

const ActionContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled.img`
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 0.5rem;
`;

const DropdownButton = styled(MoreOutlined)`
  color: ${colors.neutral00};
  font-size: x-large;
`;

const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    background: #343537;
  }
  li {
    color: #fff;
  }
  li:hover {
    background-color: #525256;
  }
`;
const StyledSkeleton = styled(Skeleton)`
  height: 12.313rem;
  padding: 2rem;
`;

const SiteCard: React.FunctionComponent<ISiteCardProps> = ({
  name,
  websiteUrl,
  handleDelete,
  projectId,
  previewUrl,
  scriptCode
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCopyCode = (code: string | null) => () => {
    if (code) {
      copy(code);
      message.success(<CopyMessage />);
    }
  };

  const CopyMessage = () => (
    <ToastMessage>
      <img src={Copyscript} />
      <p>Script tag copied to clipboard.</p>
    </ToastMessage>
  );

  const renderMenu = () => {
    return (
      <StyledMenu>
        <Menu.Item disabled={!scriptCode} onClick={handleCopyCode(scriptCode)}>
          <T id="copy_script" />
        </Menu.Item>
        <Menu.Item onClick={showModal}>
          <T id="delete" />
        </Menu.Item>
      </StyledMenu>
    );
  };

  return (
    <StyledCard>
      <StyledSkeleton loading={!previewUrl} active>
        <Link to={`editor/${projectId}`}>
          <CoverImage imgUrl={previewUrl}>
            <Overlay>
              <ActionContainer>
                <OpenDesignButton>
                  {translate('open_designer')}
                  <StyledIcon src={ArrowImg} aria-label="arrow sign" />
                </OpenDesignButton>
              </ActionContainer>
            </Overlay>
          </CoverImage>
        </Link>
      </StyledSkeleton>
      <CardFooter>
        <div>
          <ProjectName>{name.length > 34 ? name.substring(0, 34) : name}</ProjectName>
          {websiteUrl && (
            <StyledLink href={websiteUrl} target="_blank" rel="noreferrer noopener">
              {websiteUrl?.length > 34 ? websiteUrl?.substring(0, 34) : websiteUrl}
            </StyledLink>
          )}
        </div>
        <div>
          <Dropdown overlay={renderMenu()} trigger={['click']}>
            <DropdownButton />
          </Dropdown>
        </div>
      </CardFooter>
      <DeleteDialogue
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleDelete={() => handleDelete(projectId)}
      />
    </StyledCard>
  );
};

export default SiteCard;
