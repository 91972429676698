import React from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import { colors, fonts, media } from '@app/themes';
import { translate } from '../IntlGlobalProvider';

interface IDeleteDialogueProps {
  isModalOpen: boolean;
  handleCancel: () => void;
  handleDelete: () => void;
}

const StyledFooter = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  top: 30% !important;

  .ant-modal-content {
    padding: 3rem 3rem;
    width: 25rem;
    height: 14.87rem;

    background-color: ${colors.neutral90};
    box-shadow: 0px 8px 36px 0px #1e1e1e7a;
    ${media.lessThan('tablet')`
      width:100%;
      height: auto;
    `}
    border-radius:0.5rem;
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-close {
      display: none;
    }
    .ant-modal-footer {
      margin-top: 2rem;
      border: none;
    }
  }
`;

const StyledDanger = styled(Button)`
  &&.ant-btn {
    height: 2.625rem;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    ${fonts.size.regular};
    ${fonts.family.DMSans};
    font-weight: 400;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &&.ant-btn-primary {
    background-color: ${colors.danger};
  }
`;

const StyledGhostButton = styled(Button)`
  &&.ant-btn {
    height: 2.625rem;
    padding: 0.5rem 1rem;
    color: ${colors.neutral00};
    border: 1px solid ${colors.neutral00};
    border-radius: 0.375rem;
    ${fonts.size.regular};
    ${fonts.family.DMSans};
    font-weight: 400;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &&.ant-btn-primary {
    background-color: inherit;
  }
`;

const ModalTitle = styled.h3`
  color: ${colors.neutral10};
  font-family: ${fonts.family.DMSans};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin-top: 1rem;
`;

const DeleteCopy = styled.p`
  color: ${colors.neutral20};
  text-align: center;
  margin-top: -0.6rem;
`;

const DeleteDialogue: React.FunctionComponent<IDeleteDialogueProps> = ({ isModalOpen, handleCancel, handleDelete }) => {
  return (
    <>
      <StyledModal
        open={isModalOpen}
        onCancel={handleCancel}
        transitionName=""
        footer={[
          <StyledFooter key={'styled-footer'}>
            <StyledGhostButton
              type="primary"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </StyledGhostButton>
            <StyledDanger
              type="primary"
              danger
              onClick={() => {
                handleCancel();
                handleDelete();
              }}
            >
              Delete
            </StyledDanger>
          </StyledFooter>
        ]}
      >
        <ModalTitle>{translate('are_you_sure')}</ModalTitle>
        <DeleteCopy>{translate('delete_dialogue_message')}</DeleteCopy>
      </StyledModal>
    </>
  );
};

export default DeleteDialogue;
