import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  projectsLoading: false,
  projects: [],
  projectsError: null,
  projectByIdLoading: false,
  projectByIdError: false,
  projectById: null
};

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    requestGetProjects: {
      reducer: (state) => {
        state.projectsLoading = true;
      },
      prepare
    },
    requestDeleteProject: {
      reducer: (state) => {
        state.projectsLoading = true;
      },
      prepare
    },
    successGetProjects(state, action) {
      state.projectsError = null;
      state.projectsLoading = false;
      state.projects = action.payload;
    },
    successDeleteProject(state, action) {
      const deletedProjectId = action.payload;
      state.projectsLoading = false;
      state.projects = state.projects.filter((project: any) => project.id !== deletedProjectId);
    },
    failureGetProjects(state, action) {
      state.projectsError = get(action.payload, 'message', 'something_went_wrong');
      state.projectsLoading = false;
      state.projects = [];
    },
    successAddProjects(state, action) {
      state.projects = action.payload;
    }
  }
});

export const {
  requestGetProjects,
  requestDeleteProject,
  successGetProjects,
  successDeleteProject,
  failureGetProjects,
  successAddProjects
} = projectSlice.actions;

export default projectSlice.reducer;
