import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import firebaseJson from '../../firebase.json';

const getApp = () => {
  return initializeApp({
    // Your Firebase configuration object
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PREOJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID
  });
};

export const auth = getAuth(getApp());
export const firebase = getDatabase(getApp());
export const firestore = getFirestore(getApp());
export const storage = getStorage(getApp());

if (process.env.ENVIRONMENT_NAME === 'local') {
  connectAuthEmulator(auth, `http://127.0.0.1:${firebaseJson.emulators.auth.port}`);
  connectFirestoreEmulator(firestore, '127.0.0.1', firebaseJson.emulators.firestore.port);
  connectStorageEmulator(storage, '127.0.0.1', firebaseJson.emulators.storage.port);
}

export const firebaseFunctionBaseUrl =
  process.env.ENVIRONMENT_NAME === 'local'
    ? `http://localhost:${firebaseJson.emulators.functions.port}/${process.env.FIREBASE_PREOJECT_ID}/${process.env.FIREBASE_REGION}`
    : `https://${process.env.FIREBASE_REGION}-${process.env.FIREBASE_PREOJECT_ID}.cloudfunctions.net`;

export default getApp();
