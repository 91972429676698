export const HEADER_HEIGHT = '7rem';
export const MIN_SIDEBAR_WIDTH = '4.5rem';
export const MOBILE_DRAWER_BREAKPOINT = 450;
export const RECOMMENDED_EVENTS_GA = {
  SIGNUP: 'sign_up',
  LOGIN: 'login'
};
export const CUSTOM_EVENTS_ACTIONS_GA = {
  REGISTRATION_COMPLETE: 'REGISTRATION COMPLETE',
  COPY_SCRIPT: 'COPY SCRIPT'
};
export const CUSTOM_EVENTS_CATEGORY_GA = {
  HELLO_BAR: 'HELLO BAR'
};

export const HELP_URL = 'https://tuesday-labs.freshdesk.com/support/home';

export const HELLO_BAR_STYLE_ID = {
  text: 'TEXT',
  button: 'BUTTON',
  bar: 'BAR'
};

export const walkthroughSteps = [
  {
    elementId: 'helloBarContainer',
    message: 'Click the HelloBar container to view settings.'
  },
  {
    elementId: 'helloBarText',
    message: 'Now, click the HelloBar text to customize it.'
  },
  {
    elementId: 'buttonElement',
    message: 'Finally, click the button to make changes.'
  }
];

export const screenSizes = {
  desktop: 'desktop',
  tablet: 'tablet',
  mobile: 'mobile'
};

export const viewPortSizes = {
  desktop: { width: 1280, height: 1000 },
  tablet: { width: 768, height: 1024 },
  mobile: { width: 360, height: 640 }
};
