/**
 *
 * ProtectedRoute
 *
 */

import React, { ComponentType } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import PropTypes from 'prop-types';
import routeConstants from '@utils/routeConstants';
import * as Sentry from '@sentry/react';

interface ProtectedRouteProps {
  render: ComponentType<RouteComponentProps>;
  isLoggedIn: boolean;
  fullySignedUp: boolean;
  handleLogout?: () => void;
  path: string;
  exact: boolean;
}

const SentryRoute = Sentry.withSentryRouting(Route);

const ProtectedRoute = ({
  render: Component,
  isLoggedIn,
  fullySignedUp,
  handleLogout = () => {},
  ...rest
}: ProtectedRouteProps) => {
  const isGuestRoute =
    Object.keys(routeConstants)
      .filter((key) => !routeConstants[key].isProtected)
      .map((key) => routeConstants[key].route)
      .includes(rest.path) && rest.exact;

  function handleRedirection(renderProps: RouteComponentProps) {
    let to: string | undefined;

    // paths which doesn't require protected/unprotectec path check
    if (
      rest.path === routeConstants.loginRedirect.route ||
      rest.path === routeConstants.privacyPolicy.route ||
      rest.path === routeConstants.termsAndConditions.route
    ) {
      return <Component {...renderProps} />;
    }

    // get redirect route if there any
    if (isLoggedIn) {
      // user is logged in
      if (isGuestRoute && fullySignedUp) {
        to = routeConstants.dashboard.route;
      } else if (!fullySignedUp) {
        to = routeConstants.signup.route;
      }
    } else if (!isGuestRoute) {
      // not logged in and protected route
      to = routeConstants.login.route;
      handleLogout();
    }

    // no path to redirect or redirect path is same as current path
    if (!to || to === rest.path) {
      return <Component {...renderProps} />;
    }

    return <Redirect to={to} />;
  }
  return <SentryRoute {...rest} render={handleRedirection} />;
};

ProtectedRoute.propTypes = {
  render: PropTypes.any,
  isLoggedIn: PropTypes.bool,
  isUserVerified: PropTypes.bool,
  handleLogout: PropTypes.func
};

export default ProtectedRoute;
