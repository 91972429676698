import { css, Interpolation } from 'styled-components';
import { GenerateMediaFunction } from './types.d';

export const defaultBreakpoints = {
  huge: '1440px',
  large: '1170px',
  medium: '768px',
  small: '450px'
};

function getSizeFromBreakpoint<Breakpoints>(
  breakpointValue: keyof Breakpoints,
  breakpoints: Breakpoints
): Interpolation<object> {
  return breakpoints[breakpointValue] as string;
}

export const generateMedia: GenerateMediaFunction = (breakpoints) => {
  return {
    lessThan:
      (breakpoint) =>
      (...args) =>
        css`
          @media (max-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
            ${css(...args)}
          }
        `,
    greaterThan:
      (breakpoint) =>
      (...args) =>
        css`
          @media (min-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
            ${css(...args)}
          }
        `,
    between:
      (firstBreakpoint, secondBreakpoint) =>
      (...args) =>
        css`
          @media (min-width: ${getSizeFromBreakpoint(
              firstBreakpoint,
              breakpoints
            )}) and (max-width: ${getSizeFromBreakpoint(secondBreakpoint, breakpoints)}) {
            ${css(...args)}
          }
        `
  };
};
