/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { AnyAction, combineReducers } from 'redux';

import languageProvider from '@containers/LanguageProvider/reducer';
import authToken from '@containers/TokenContainer/reducer';
import editor from '@containers/Editor/reducer';
import projects from '@containers/ProjectsProvider/reducer';
import { createAction } from '@reduxjs/toolkit';

export const logoutAction = createAction('USER_LOGOUT');

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducer = {}) {
  const appReducer = combineReducers({
    ...injectedReducer,
    languageProvider,
    authToken,
    editor,
    projects
  });

  const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    if (action.type === logoutAction.type) {
      return appReducer(undefined, { type: undefined });
    }

    return appReducer(state, action);
  };
  return rootReducer;
}
