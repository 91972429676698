import { colors } from '@app/themes';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../Logo';

type NavbarProps = {
  children?: React.ReactNode;
};

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1.15rem 1.5rem;
  background-color: ${colors.neutral80};
  border-bottom: 1px solid ${colors.neutral50};
  width: 100%;
`;

const Stack = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

function Navbar({ children }: NavbarProps) {
  return (
    <FlexContainer>
      <Link to="/">
        <Logo />
      </Link>

      <Stack>{children}</Stack>
    </FlexContainer>
  );
}

export default React.memo(Navbar);
