import { firebaseFunctionBaseUrl, firestore } from '../firebase-config';
import { setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { UserStore } from '@app/containers/TokenContainer/types';
import { verifyPasswordResetCode, confirmPasswordReset, User } from 'firebase/auth';

export async function addUser(uidData: string, name: string, email: string) {
  try {
    await setDoc(doc(firestore, 'users', uidData), {
      uid: uidData,
      name: name,
      signUpStatus: false,
      email: email,
      subscriber: false,
      isFirstTime: true,
      app1: false,
      app2: false,
      app3: false,
      app4: false,
      app5: false
    });
  } catch (err) {
    return err;
  }
}

export async function lastSignupPhase(uid: string, designation: string, company: string) {
  try {
    await updateDoc(doc(firestore, 'users', uid), {
      designation: designation,
      companyName: company,
      signUpStatus: true
    });
  } catch (err) {
    return err;
  }
}

export async function unsubscribeFlag(emailData: any) {
  try {
    await setDoc(doc(firestore, 'unsubscribe', emailData), {
      email: emailData,
      emailSubscriber: false
    });
  } catch (err) {
    return err;
  }
}

export async function getUser(userAuth: User): Promise<UserStore> {
  const usersRef = await getDoc(doc(firestore, 'users', userAuth.uid));
  const userFirestoreData = usersRef.data() as UserStore;

  return { uid: userAuth.uid, email: userAuth.email!, ...userFirestoreData };
}

type WebsitePreviewResult = { url?: string; error?: string };

export const getWebsitePreviewUrl = async (
  userId: string,
  projectId: string,
  websiteUrl: string
): Promise<WebsitePreviewResult> => {
  const resp = await fetch(
    `${firebaseFunctionBaseUrl}/getWebsitePreview?userId=${userId}&projectId=${projectId}&websiteUrl=${websiteUrl}`
  );
  const jsonResponse = await resp.json();
  return jsonResponse;
};

export async function handleResetPassword(auth: any, actionCode: any, newPassword: string) {
  verifyPasswordResetCode(auth, actionCode)
    .then(() => {
      confirmPasswordReset(auth, actionCode, newPassword)
        // .then((resp) => {
        //   return resp;
        // })
        .catch((error) => {
          return error;
        });
    })
    .catch((error) => {
      return error;
    });
}

export async function markProjectAsPublished(userId: string, projectId: string) {
  const projectRef = doc(firestore, 'users', userId, 'projects', projectId);
  return updateDoc(projectRef, { isPublished: true });
}
