import React from 'react';
import { put, call, takeLatest } from 'redux-saga/effects';
import {
  requestSignUp,
  successSignup,
  failureSignup,
  successAutoLogin,
  requestAutoLogin,
  requestFormUpdate,
  successLogout,
  requestLogOut,
  requestForgotPassword,
  successRequestGetUser,
  failureRequestGetUser,
  requestGetUser,
  requestCustomToken,
  failureCustomToken,
  failureAutoLogin,
  requestGetSites,
  successGetSites,
  requestUnsubscribe,
  failureRequestForgotPassword,
  successRequestForgotPassword,
  requestResetPassword,
  failureRequestResetPassword
} from './reducer';
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential
} from 'firebase/auth';
import { auth } from '../../firebase-config';
import get from 'lodash-es/get';
import { message } from 'antd';
import { addUser, getUser, handleResetPassword, lastSignupPhase, unsubscribeFlag } from '@app/utils/firebaseUtils';
import history from '@app/utils/history';
import routeConstants from '@app/utils/routeConstants';
import { UserPartialSignup, UserStore } from './types';
import { getCustomToken, getWebflowSites } from '@app/utils/firebaseAuthUtils';
import { successAddProjects } from '../ProjectsProvider/reducer';
import { ToastMessage } from '@app/components';
import { updateDocument } from '@app/utils/firestoreUtils';

export function* signUpUser(action: any): Generator<any, any, any> {
  const { email, password, name } = action.payload;

  try {
    const response = yield call(createUserWithEmailAndPassword, auth, email, password);

    const { user } = response;

    if (user) {
      yield call(addUser, user.uid, name, email);
      const partialUserSignup: UserPartialSignup = {
        uid: user.uid,
        name: name,
        email: email,
        signUpStatus: false
      };
      yield put(successSignup(partialUserSignup));
    } else {
      yield put(failureSignup('something went wrong'));
    }
  } catch (err: any) {
    yield put(failureSignup(err.message));
    if (err?.code === 'auth/email-already-in-use') {
      /* 'Account of this email id already exists' */
      message.error(<ToastMessage type="Error" message="Account of this email id already exists" />);
    } else {
      message.error(<ToastMessage type="Error" message={get(err, 'message', 'something_went_wrong')} />);
    }
  }
}

export function* updateForm(action: any): Generator<any, any, any> {
  const { designation, company } = action.payload;
  const user: UserPartialSignup = action.payload.user;

  try {
    yield call(lastSignupPhase, user.uid, designation, company);

    // add updated user info into firestore
    const userAuth: UserStore = {
      ...user,
      designation,
      companyName: company,
      signUpStatus: true,
      isFirstTime: true
    };
    yield put(successSignup(userAuth));
    /* 'Signed Up Successfully !'; */
    message.success(<ToastMessage type="Success" message="Signed Up Successfully !" />);

    history.push(`${routeConstants.dashboard.route}`);
  } catch (err: any) {
    yield put(failureSignup(err.message));
    message.error(<ToastMessage type="Error" message={get(err, 'message', 'something_went_wrong')} />);
  }
}

export function* updateUserWithFirstime(action: any): Generator<any, any, any> {
  const { uid, isFirstTime } = action.payload;

  try {
    yield call(updateDocument, 'users', [uid], {
      isFirstTime
    });
  } catch (err: any) {
    message.error(<ToastMessage type="Error" message={get(err, 'message', 'something_went_wrong')} />);
  }
}

export function* autoLoginUser(action: any): Generator<any, any, any> {
  const user: User = action.payload;
  try {
    // get user information from firestore
    const userFireStore = yield call(getUser, user);
    yield put(successAutoLogin(userFireStore));
  } catch (err: any) {
    yield put(failureAutoLogin(err.message));
    message.error(<ToastMessage type="Error" message={get(err, 'message', 'something_went_wrong')} />);
  }
}

export function* userLogout(): Generator<any, any, any> {
  try {
    yield call(signOut, auth);
    yield put(successLogout());
    history.push(`${routeConstants.login.route}`);
  } catch (err: any) {
    message.error(<ToastMessage type="Error" message={get(err, 'message', 'something_went_wrong')} />);
  }
}

export function* forgotPassword(action: any): Generator<any, any, any> {
  const { email } = action.payload;

  try {
    yield call(sendPasswordResetEmail, auth, email);
    yield put(successRequestForgotPassword());
    message.success(<ToastMessage type="Success" message="'Password reset mail sent successfully !'" />);
    history.push(`${routeConstants.login.route}`);
  } catch (err: any) {
    yield put(failureRequestForgotPassword(err.message));
    message.error(<ToastMessage type="Error" message="Please check the email and try again." />);
  }
}
export function* unsubscribe(action: any): Generator<any, any, any> {
  const { email } = action.payload;

  try {
    yield call(unsubscribeFlag, email);
    message.success('Unsubscribed successfully !');
    history.push(`${routeConstants.login.route}`);
  } catch (err: any) {
    message.error(<ToastMessage type="Error" message={get(err, 'message', 'something_went_wrong')} />);
  }
}

export function* signInUser(action: any): Generator<any, any, any> {
  const { email, password } = action.payload;

  try {
    const response: UserCredential = yield call(signInWithEmailAndPassword, auth, email, password);
    const { user } = response;
    const userFireStore = yield call(getUser, user);
    yield put(successRequestGetUser(userFireStore));
  } catch (err: any) {
    yield put(failureRequestGetUser(err.message));
    message.error(
      <ToastMessage type="Error" message="Login unsuccessful. Please check the email and password and try again." />
    );
  }
}

export function* customSignUp(action: any): Generator<any, any, any> {
  const { code } = action.payload;
  try {
    const response = yield call(getCustomToken, code);

    if (response.error) {
      throw Error('Failure in retrieving code from webflow auth');
    }
    const user = yield call(signInWithCustomToken, auth, response.token);
    const partialUserSignup: UserPartialSignup = {
      uid: user.uid,
      name: user.displayName,
      email: user.email,
      signUpStatus: false
    };
    yield put(successSignup(partialUserSignup));
  } catch (err: any) {
    yield put(failureCustomToken(err));
  }
  history.push({ pathname: routeConstants.signup.route });
}

export function* updateUserWebflowSites(actions: any): Generator<any, any, any> {
  const { code, user } = actions.payload;
  try {
    const response = yield call(getWebflowSites, code, user.uid);

    if (response.error) {
      throw Error(response.error);
    }

    if (response.projects?.length > 0) {
      yield put(successAddProjects(response.projects));
    }
    yield put(successGetSites());
  } catch (err: any) {
    message.error(<ToastMessage type="Error" message={get(err, 'message', 'something_went_wrong')} />);
    yield put(failureCustomToken(err));
    history.push(routeConstants.login.route);
  }
}

export function* resetPassword(action: any): Generator<any, any, any> {
  const { password, code } = action.payload;

  try {
    const response = yield call(handleResetPassword, auth, code, password);
    history.push(`${routeConstants.login.route}`);
    if (response.error) {
      throw Error(response.error);
    }
    message.success(<ToastMessage type="Success" message="Password reset successful!" />);
  } catch (err: any) {
    yield put(failureRequestResetPassword(err.message));
    message.error(<ToastMessage type="Error" message="Please check the link and try again." />);
  }
}

// Individual exports for testing
export default function* TokenContainerSaga() {
  yield takeLatest(requestFormUpdate.toString(), updateForm);
  yield takeLatest(requestSignUp.toString(), signUpUser);
  yield takeLatest(requestAutoLogin.toString(), autoLoginUser);
  yield takeLatest(requestLogOut.toString(), userLogout);
  yield takeLatest(requestForgotPassword.toString(), forgotPassword);
  yield takeLatest(requestUnsubscribe.toString(), unsubscribe);
  yield takeLatest(requestGetUser.toString(), signInUser);
  yield takeLatest(requestCustomToken.toString(), customSignUp);
  yield takeLatest(requestGetSites.toString(), updateUserWebflowSites);
  yield takeLatest(requestResetPassword.toString(), resetPassword);
}
