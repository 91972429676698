import React from 'react';
import styled from 'styled-components';
import deleteMessage from 'images/deleteMessage.svg';
import SuccessMessage from 'images/checkcircle.svg';
import If from '../If';

interface IToastMessageProps {
  children?: React.ReactNode;
  type?: 'Success' | 'Error';
  message?: string;
}

const StyledMessage = styled.div`
  display: flex;
  justify-content: start;
  gap: 8px;
`;

const ToastMessage: React.FunctionComponent<IToastMessageProps> = ({ children, type, message }) => {
  return (
    <StyledMessage>
      <If condition={type === 'Success' || type === 'Error'} otherwise={children}>
        {type === 'Error' ? <img src={deleteMessage} alt="error" /> : <img src={SuccessMessage} alt="success" />}
        <p>{message}</p>
      </If>
    </StyledMessage>
  );
};

export default ToastMessage;
