import React from 'react';
import NotFound from '@containers/NotFoundPage/Loadable';
import LoginContainer from '@containers/LoginContainer/Loadable';
import SignUpContainer from '@containers/SignUpContainer/Loadable';
import ForgotPasswordContainer from '@containers/ForgotPasswordContainer/Loadable';
import UnsubscribeContainer from '@containers/UnsubscribeContainer/Loadable';
import routeConstants, { RouteConstant } from '@utils/routeConstants';
import Editor from '@containers/Editor/Loadable';
import Dashboard from '@containers/Dashboard/Loadable';
import LoginRedirect from '@containers/LoginRedirect/Loadable';
import ResetPasswordContainer from '@containers/ResetPasswordContainer/Loadable';
import PrivacyPolicy from '@containers/PrivacyPolicy/Lodable';
import TermsAndConditions from '@containers/TermsAndConditions/Loadable';

type RouteConfig = Record<string, { component?: React.FC<any> } & Partial<RouteConstant>>;

export const routeConfig: RouteConfig = {
  home: {
    ...routeConstants.home
  },
  dashboard: {
    component: Dashboard,
    ...routeConstants.dashboard
  },
  login: {
    component: LoginContainer,
    ...routeConstants.login
  },
  signUp: {
    component: SignUpContainer,
    ...routeConstants.signup
  },
  editor: {
    component: Editor,
    ...routeConstants.editor
  },
  forgotPassword: {
    component: ForgotPasswordContainer,
    ...routeConstants.forgotPassword
  },
  unsubscribe: {
    component: UnsubscribeContainer,
    ...routeConstants.unsubscribe
  },
  redirectLogin: {
    component: LoginRedirect,
    ...routeConstants.loginRedirect
  },
  resetPassword: {
    component: ResetPasswordContainer,
    ...routeConstants.resetPassword
  },
  privacyPolicy: {
    component: PrivacyPolicy,
    ...routeConstants.privacyPolicy
  },
  termsAndConditions: {
    component: TermsAndConditions,
    ...routeConstants.termsAndConditions
  },
  // always keep this at the end
  notFoundPage: {
    component: NotFound,
    route: '/'
  }
};
