/**
 * app.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// Import all the third party stuff
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import history from '@utils/history';
import 'sanitize.css/sanitize.css';
import * as Sentry from '@sentry/react';
import { StyleSheetManager, ShouldForwardProp } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

// Import root app
import App from '@containers/App';

// Import Language Provider
import LanguageProvider from '@containers/LanguageProvider';
import ScrollToTop from '@components/ScrollToTop';
// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

import configureRootStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';
import { Router } from 'react-router-dom';
import { initGA } from './services/googleAnalytics';

// Initialize Google Analytics
initGA();

// Create redux store with history
const initialState = {};
const { store, persistor } = configureRootStore(initialState);
const MOUNT_NODE = document.getElementById('app');
let root: Root;

// This implements the default behavior from styled-components v5
const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https://staging-apps.labs.tuesday.is', 'https://apps.labs.tuesday.is'],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    }),
    new Sentry.Replay(),
    new Sentry.BrowserProfilingIntegration()
  ],
  profilesSampleRate: 1.0,
  environment: process.env.ENVIRONMENT_NAME,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const render = (messages: typeof translationMessages) => {
  root = createRoot(MOUNT_NODE as HTMLElement);
  root.render(
    <Sentry.ErrorBoundary fallback={<h1>Something went wrong</h1>}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LanguageProvider messages={messages}>
            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
              <Router history={history}>
                <ScrollToTop>
                  <App />
                </ScrollToTop>
              </Router>
            </StyleSheetManager>
          </LanguageProvider>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    root.unmount();
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  Promise.resolve(import('intl'))
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('@lcdp/offline-plugin/runtime').install({
    onUpdated: () => {
      // Reload the webpage to load into the new version
      window.location.reload();
    }
  }); // eslint-disable-line global-require
}
