import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  authLoading: false,
  user: null,
  authError: null,
  autoLoginLoading: false,
  addSitesLoading: false,
  redirect: false
};

const tokenSlice = createSlice({
  name: 'userToken',
  initialState,
  reducers: {
    requestAutoLogin: {
      reducer: (state) => {
        state.autoLoginLoading = true;
      },
      prepare
    },
    successAutoLogin(state, action) {
      state.authError = null;
      state.autoLoginLoading = false;
      state.user = action.payload;
    },
    failureAutoLogin(state, action) {
      state.authError = action.payload;
      state.autoLoginLoading = false;
      state.user = null;
    },
    requestSignUp: {
      reducer: (state) => {
        state.authLoading = true;
      },
      prepare
    },
    successSignup(state, action) {
      state.authError = null;
      state.user = action.payload;
      state.authLoading = false;
    },
    failureSignup(state, action) {
      state.authError = get(action.payload, 'message', 'something_went_wrong');
      state.authLoading = false;
    },
    requestFormUpdate: {
      reducer: (state) => {
        state.authLoading = true;
      },
      prepare
    },
    requestGetUser: {
      reducer: (state) => {
        state.authLoading = true;
      },
      prepare
    },
    successRequestGetUser(state, action) {
      state.authError = null;
      state.user = action.payload;
      state.authLoading = false;
    },
    failureRequestGetUser(state, action) {
      state.authError = get(action.payload, 'message', 'something_went_wrong');
      state.authLoading = false;
    },
    requestLogOut: {
      reducer: (state) => {
        state.authLoading = true;
      },
      prepare
    },
    successLogout(state) {
      state.authError = null;
      state.authLoading = false;
      state.user = null;
    },
    requestForgotPassword: {
      reducer: (state) => {
        state.authLoading = true;
      },
      prepare
    },
    successRequestForgotPassword(state) {
      state.authError = null;
      state.authLoading = false;
    },
    failureRequestForgotPassword(state, action) {
      state.authError = get(action.payload, 'message', 'something_went_wrong');
      state.authLoading = false;
    },
    requestUnsubscribe: {
      reducer: (state) => {
        state.authLoading = true;
      },
      prepare
    },
    requestCustomToken: {
      reducer: (state) => {
        state.authLoading = true;
      },
      prepare
    },
    failureCustomToken(state, action) {
      state.authLoading = false;
      state.authError = get(action.payload, 'message', 'something_went_wrong');
    },
    requestGetSites: {
      reducer: (state) => {
        state.addSitesLoading = true;
        state.redirect = false;
      },
      prepare
    },
    successGetSites(state) {
      state.authError = null;
      state.addSitesLoading = false;
      state.redirect = true;
    },
    failureGetSites(state, action) {
      state.addSitesLoading = false;
      state.authError = get(action.payload, 'message', 'something_went_wrong');
      state.redirect = true;
    },
    resetLoginRedirect(state) {
      state.addSitesLoading = false;
      state.authError = null;
      state.redirect = false;
    },
    requestResetPassword: {
      reducer: (state) => {
        state.authLoading = true;
      },
      prepare
    },
    successRequestResetPassword(state, action) {
      state.authError = null;
      state.user = action.payload;
      state.authLoading = false;
    },
    failureRequestResetPassword(state, action) {
      state.authError = get(action.payload, 'message', 'something_went_wrong');
      state.authLoading = false;
    }
  }
});

export const {
  requestFormUpdate,
  requestSignUp,
  successSignup,
  failureSignup,
  requestAutoLogin,
  successAutoLogin,
  failureAutoLogin,
  requestLogOut,
  successLogout,
  requestForgotPassword,
  failureRequestForgotPassword,
  successRequestForgotPassword,
  requestUnsubscribe,
  requestGetUser,
  failureRequestGetUser,
  successRequestGetUser,
  requestCustomToken,
  failureCustomToken,
  requestGetSites,
  successGetSites,
  failureGetSites,
  resetLoginRedirect,
  requestResetPassword,
  failureRequestResetPassword,
  successRequestResetPassword
} = tokenSlice.actions;

export default tokenSlice.reducer;
