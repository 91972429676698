import history from './history';
import isEmpty from 'lodash-es/isEmpty';

export const mapKeysDeep = <T>(obj: T, fn: (key: string) => string): T =>
  Array.isArray(obj)
    ? obj.map((val) => mapKeysDeep(val, fn))
    : typeof obj === 'object' && obj
    ? Object.keys(obj).reduce((acc, current) => {
        const key = fn(current);
        const val = obj[current as keyof T];
        acc[key] = val !== null && typeof val === 'object' ? mapKeysDeep(val, fn) : val;
        return acc;
      }, {} as any)
    : obj;

export const isLocal = () => {
  try {
    if (process.env.IS_LOCAL) {
      const local = JSON.parse(process.env.IS_LOCAL);
      return typeof local === 'boolean' && local;
    }
  } catch {
    // continue regardless of error
  }
  return false;
};

interface SetQueryParamOptions {
  param: string;
  value?: string | number;
  deleteParam?: boolean;
  historyOp?: 'push' | 'replace';
}

export const setQueryParam = ({ param, value, deleteParam, historyOp = 'push' }: SetQueryParamOptions) => {
  const urlParams = new URLSearchParams(history.location.search);
  if (deleteParam) {
    urlParams.delete(param);
  } else {
    urlParams.set(param, String(value));
  }
  if (typeof history[historyOp] === 'function') {
    history[historyOp]({ search: urlParams.toString() });
  } else {
    throw new Error('Invalid history operation');
  }
};

export const prepare = (payload: any) => ({ payload });

export const getWebsitePreview = (previewUrl: string) => {
  if (isEmpty(previewUrl)) {
    return 'https://firebasestorage.googleapis.com/v0/b/tuesday-labs.appspot.com/o/preview%2Fpreview_png.png?alt=media&token=371540e5-cd2c-46e3-ad15-3e6db57b6f56';
  }
  return previewUrl;
};

export const handleWebflowLogin = () => {
  const AUTH_URL = 'https://webflow.com/oauth/authorize';
  const SCOPE =
    'assets:read authorized_user:read cms:read custom_code:read ecommerce:read forms:read pages:read sites:read users:read';
  // Construct the OAuth authorization URL
  const authUrl = `${AUTH_URL}?client_id=${process.env.CLIENT_ID}&scope=${SCOPE}&response_type=code`;

  // Redirect the user to the Webflow OAuth authorization URL
  window.location.href = authUrl;
};

export const constructHexValue = (palette: any) => {
  const colorArray = [];
  const colorsObj: { [key: string]: string } = {
    vibrant: palette.Vibrant.getHex(),
    darkVibrant: palette.DarkVibrant.getHex(),
    lightVibrant: palette.LightVibrant.getHex(),
    muted: palette.Muted.getHex(),
    darkMuted: palette.DarkMuted.getHex(),
    lightMuted: palette.LightMuted.getHex()
  };

  for (const key in colorsObj) {
    if (Object.prototype.hasOwnProperty.call(colorsObj, key)) {
      colorArray.push(colorsObj[key]);
    }
  }

  return colorArray.slice(2);
};

export const walkthroughSteps = [
  {
    id: 'bar',
    instructions: 'Hello there, welcome to Hellobar. Click on your Hellobar to start editing!'
  },
  {
    id: 'headerText',
    instructions: 'Time to change this generic text. Click on this text and start editing!'
  },
  {
    id: 'button',
    instructions: 'Now click on the Action Button to change the color, text and URL.'
  }
];
