import React from 'react';
import styled from 'styled-components';
import { Button, Col } from 'antd';
import { translate } from '../IntlGlobalProvider';
import { colors, fonts, media } from '@app/themes';
import AddIcon from '@images/addsite.svg';
import { Tooltip } from 'react-tooltip';
import { InfoCircleOutlined } from '@ant-design/icons';

const StyledColumn = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.375rem;
  border-bottom: 1px solid #484c53;
`;

const StyledHeader = styled.h3`
  margin: 0;
  ${fonts.size.extraLarge};
  ${fonts.family.DMSans};
  color: ${colors.neutral00};
  font-weight: 700;

  ${media.between('tablet', 'desktop')`
    ${fonts.size.large};
  `}

  ${media.lessThan('tablet')`
    ${fonts.size.small};
  `}
`;

const StyledButton = styled(Button)`
  &&.ant-btn {
    height: 2.625rem;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    ${fonts.size.regular};
    ${fonts.family.DMSans};
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
  &&.ant-btn-primary {
    background-color: ${colors.primary100};
  }
`;

const StyledImg = styled.img`
  margin-left: 0.5rem;
  height: 1rem;
  width: 1rem;
`;

const Stack = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

interface IUserNavBarProps {
  name: string;
  onClick: () => void;
}

const UserNavBar: React.FunctionComponent<IUserNavBarProps> = ({ name, onClick }) => {
  return (
    <StyledColumn>
      <StyledHeader>
        {name}&apos;s {translate('workspace')}
      </StyledHeader>
      <Stack>
        <StyledButton type="primary" onClick={onClick}>
          Add Site <StyledImg src={AddIcon} />
        </StyledButton>

        <InfoCircleOutlined
          data-tooltip-id="designer-extension-suggestion"
          style={{ fontSize: 24, color: '#f3f3f3' }}
        />
        <Tooltip id="designer-extension-suggestion" place="top-end" offset={20}>
          After authorizing your site, you can access the app directly from the Webflow Designer - &apos;Apps&apos;
          section
        </Tooltip>
      </Stack>
    </StyledColumn>
  );
};

export default UserNavBar;
