import { createGlobalStyle } from 'styled-components';
import { colors } from '@app/themes';
import DMSansRegular from './font/DMSansRegular.ttf';
import DMSansMedium from './font/DMSansMedium.ttf';
import DMSansBold from './font/DMSansBold.ttf';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'DM Sans';
    src: url(${DMSansRegular});
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DMSansMedium});
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DMSansBold});
    font-weight: 700;
    font-style: normal;
  }

  html,
  body {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'DM Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'DM Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    background-color: ${colors.secondaryText};
    min-height: 100%;
    min-width: 100%;
  }

  p,
  span,
  button,
  label {
    font-family: 'DM Sans';
    line-height: 1.5em;
    margin-bottom: 0;
  }

  .ant-message-notice-content{
   height: 2.81rem;
  }

  .ant-message-custom-content {
    display: flex;
    justify-content:start;
    width: 100%;
    height: 100%;
  }

  .ant-message-success {
     background-color: ${colors.successMessage};
     color: ${colors.successMessageText};
     padding:0.75rem 1rem;
     border-radius:6px;

    .anticon-check-circle{
      display: none;
    }
  }

  .ant-message-error {
     background-color: ${colors.danger70};
     color: ${colors.danger};
     padding: 0.75rem 1rem;
     border-radius: 6px;

     .anticon-close-circle{
      display: none;
    }
  }

  .ant-color-picker-trigger{
    width:0;
    height: 0;
    border:none;
    background: none;
    .ant-color-picker-color-block{
    width:0;
    height: 0;
    .ant-color-picker-color-block-inner{
      background:none !important;
    }
    }
  }

  .react-colorful {
  width: auto !important;
  height: 15.87rem !important;

  @media screen and (max-width:1500px){
    height: 7rem !important;
  }
 
 }
 
.react-colorful__saturation {
  margin-bottom: 15px;
  border-radius: 0px !important;
  border-bottom: none;

  
}

.react-colorful__saturation-pointer {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
}

 .react-colorful__hue,
 .react-colorful__alpha {
  height: 10px !important;
  border-radius: 5px;
}
 .react-colorful__alpha{
  border-radius: 5px !important;
  margin-top:0.5rem;
}

 .react-colorful__hue-pointer,
 .react-colorful__alpha-pointer {
  width: 10px !important;
  height: 10px !important;
  border: 1px solid black !important;
}

  /* Custom styles for the color picker container */
  
  .ant-switch[aria-checked="true"] {
     background-color: ${colors.primary100};
      &:hover{
       background-color: ${colors.primary100};
     }
  }
  .ant-switch[aria-checked="false"] {
     background-color:${colors.neutral50} ;
    
     &:hover{
      background-color:${colors.neutral50} ;
     }
  }

`;

export default GlobalStyle;
