import { UserSites } from '@app/containers/TokenContainer/types';
import { firebaseFunctionBaseUrl } from '@app/firebase-config';

type CustomTokenResult = { token?: string; error?: string };

export const getCustomToken = async (code: string): Promise<CustomTokenResult> => {
  const resp = await fetch(`${firebaseFunctionBaseUrl}/signInWithWebflow?code=${code}`);
  const jsonResponse = await resp.json();
  return jsonResponse;
};

type WebflowSitesResult = { sites?: UserSites[]; error?: string };

export const getWebflowSites = async (code: string, userId: string): Promise<WebflowSitesResult> => {
  const resp = await fetch(`${firebaseFunctionBaseUrl}/updateUserWebflowSites?code=${code}&userId=${userId}`);
  const jsonResponse = await resp.json();
  return jsonResponse;
};
