import { colors } from '@app/themes';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  min-height: 100vh;
  position: relative;
  background-color: ${colors.neutral70};
  overflow: auto;
`;

const MainBody = styled.main`
  height: 90vh;
  overflow-y: auto;
  background-color: ${colors.neutral80};
`;

const TextContainer = styled.div`
  margin: 2rem;
  margin-bottom: 0;
  background: ${colors.neutral90};
  padding: 5rem 0px;
`;

const TextContent = styled.div`
  max-width: 50rem;
  width: 100%;
  margin: auto;
  color: ${colors.neutral10};
  padding: 2rem;

  h1,
  h2 {
    color: ${colors.neutral10};
  }
  h1 {
    font-size: 2.5rem;
  }
  p {
    margin-bottom: 2rem;
    font-size: 1rem;
    color: ${colors.neutral20};
  }

  li {
    font-size: 1rem;
  }

  a {
    color: ${colors.primary80};
  }
`;

const ContentWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Container>
      <MainBody>
        <TextContainer>
          <TextContent>{children}</TextContent>
        </TextContainer>
      </MainBody>
    </Container>
  );
};

export default ContentWrapper;
