import {
  addDoc,
  collection,
  doc,
  FieldPath,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  WhereFilterOp,
  deleteDoc
} from 'firebase/firestore';
import { firestore } from '../firebase-config';

export type WhereCondition = {
  LHS: string | FieldPath;
  rule: WhereFilterOp;
  RHS: string | boolean;
};

export const getAllDocuments = async (
  docCollection: string,
  collectionPath: string[],
  conditions: WhereCondition[]
) => {
  const queryCollection = collection(firestore, docCollection, ...collectionPath);

  const queryWhere = conditions.map((condition) => where(condition.LHS, condition.rule, condition.RHS));

  if (queryWhere.length > 0) {
    const q = await query(queryCollection, ...queryWhere);

    const docs = await getDocs(q);
    return docs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } else {
    const docs = await getDocs(queryCollection);
    return docs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  }
};

export const getDocument = async (docCollection: string, collectionPath: string[]) => {
  const docRef = await doc(firestore, docCollection, ...collectionPath);
  const res = await getDoc(docRef);
  if (!res.exists()) {
    throw Error('Does not exits');
    return;
  }
  const data = await res.data();
  return { id: res.id, ...data };
};

export const addDocument = async (docCollection: string, pathSegments: string[], data: any) => {
  const collectionRef = collection(firestore, docCollection, ...pathSegments);

  const response = await addDoc(collectionRef, { ...data });

  const dataId = await { id: response.id };

  return dataId;
};

export const updateDocument = async (docCollection: string, pathSegments: string[], data: any) => {
  const docRef = doc(firestore, docCollection, ...pathSegments);
  await updateDoc(docRef, { ...data });
};

export const deleteDocument = async (docCollection: string, pathSegments: string[]) => {
  const docRef = doc(firestore, docCollection, ...pathSegments);
  await deleteDoc(docRef);
};

export const parseJsonFromStorageLink = async (urlLink: string | null) => {
  if (!urlLink) {
    return {};
  }
  const fetchUrl = await fetch(urlLink);

  const response = await fetchUrl.json();

  if (response.error) {
    throw Error(response.error.message);
  }

  return response;
};
