/**
 * Create the store with dynamic reducers
 */

import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import storage from 'redux-persist/lib/storage';
import createReducer from './reducers';
import { createInjectorsEnhancer } from 'redux-injectors';
import * as Sentry from '@sentry/react';
import { configureStore, applyMiddleware } from '@reduxjs/toolkit';

// redux persit configuration
const persistConfig = {
  version: 1,
  transforms: [immutableTransform()],
  key: 'root',
  blacklist: ['home', 'launchDetails'],
  storage
};

const persistedReducer = persistReducer(persistConfig, createReducer());

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export type RootState = ReturnType<typeof persistedReducer>;

export default function configureRootStore(initialState: object) {
  const reduxSagaMonitorOptions = {};

  const shouldUseDevtools = process.env.NODE_ENV !== 'production' && typeof window === 'object';

  //  sagaMiddleware: Makes redux-sagas work
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const runSaga = sagaMiddleware.run;

  const injectEnhancer = createInjectorsEnhancer({
    createReducer,
    runSaga
  });

  const store = configureStore({
    reducer: persistedReducer,
    devTools: shouldUseDevtools,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    enhancers: [applyMiddleware(sagaMiddleware), injectEnhancer, sentryReduxEnhancer]
  });
  const persistor = persistStore(store);

  return { store, persistor };
}
